<template>
  <div class="layout-title">
    <!--    <div v-if="img && img.src" class="layout-title__img l-hidden-ms-down">-->
    <!--      <asset-img :src="img.src" :alt="img.alt || ''" />-->
    <!--    </div>-->
    <div v-if="icon" class="layout-title__img l-hidden-ms-down">
      <svg-icon size="os" :name="icon" />
    </div>
    <div class="layout-title__content">
      <div class="layout-title__content">
        <h1 class="layout-title__title page-title hover">
          <slot name="title">{{ title }}</slot>
        </h1>
        <div class="layout-title__statuses">
          <main-label
            v-if="status && !task && !state"
            :color="status?.color"
            class="layout-title__status"
          >
            {{
              $t(`${['balancer', 'pool', 'member'].includes(type) ? type : 'status'}.${status.key}`)
            }}
            <!--          <base-loader-->
            <!--            v-if="['balancer', 'pool', 'member'].includes(type) && state.key.includes('PENDING')"-->
            <!--            :color="status.color"-->
            <!--            :size="true"-->
            <!--          />-->
          </main-label>
          <main-label
            v-if="status && state"
            :color="state?.key.includes('PENDING') ? state.color : status.color"
            class="layout-title__status"
          >
            {{
              state.key.includes('PENDING')
                ? $t(
                    `${['balancer', 'pool', 'member'].includes(type) ? type : 'status'}.${
                      status.key
                    }`
                  )
                : $t(
                    `${['balancer', 'pool', 'member'].includes(type) ? type : 'status'}.${
                      status.key
                    }`
                  )
            }}
            <base-loader
              v-if="['balancer', 'pool', 'member'].includes(type) && state.key.includes('PENDING')"
              :color="status?.color"
              :size="true"
            />
          </main-label>
          <main-label
            v-if="status && task"
            :color="task?.key !== 'нет' ? task?.color : status?.color"
            class="layout-title__status"
          >
            {{ task?.key !== 'нет' ? $t(`task.${task?.key}`) : $t(`status.${status?.key}`) }}
            <base-loader v-if="task && task.key !== 'нет'" :color="task.color" :size="true" />
          </main-label>
          <main-label
            v-if="state && !['balancer', 'pool', 'member'].includes(type)"
            :color="state?.color"
            class="layout-title__status"
          >
            {{ $t(`status.${state.key}`) }}
          </main-label>
        </div>
        <div v-if="$slots?.btn" class="layout-title__btn l-md-up">
          <slot name="btn" />
        </div>
        <div v-if="id" class="layout-title__address medium-text">
          {{ '#' + id }}
        </div>
        <div v-if="address" class="layout-title__address medium-text">
          {{ address }}
        </div>
        <div v-if="created" class="layout-title__address medium-text">
          {{ date(created) }}
          {{ time(created) }}
        </div>
        <base-specs v-if="specs" v-bind="specs" class="layout-title__specs medium-text" />
      </div>
      <div v-if="description" class="layout-title__content-margin">
        {{ description }}
      </div>
      <div v-if="deletable && type === 'server'" class="layout-title__content error">
        <label class="typo__label section-header medium-title error-color">{{
          $t('deletable')
        }}</label>
      </div>
      <div v-if="deletable && type === 'disk'" class="layout-title__content error">
        <label class="typo__label section-header medium-title error-color">{{
          $t('deletableDisk')
        }}</label>
      </div>
    </div>
  </div>
</template>

<script>
import MainLabel from '@/components/Labels/MainLabel.vue';
import BaseSpecs from '@/components/Specs/BaseSpecs.vue';
import { format } from 'date-fns';
export default {
  name: 'LayoutStackTitleNew',
  components: { MainLabel, BaseSpecs },
  props: {
    img: {
      type: Object,
      default: null,
      validator: obj => !!obj && obj.src,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
      validator: value => ['public', '', 'private'].includes(value),
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    state: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    task: {
      type: Object,
      default: null,
      validator: obj => obj.color && obj.key,
    },
    address: {
      type: String,
      default: null,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    created: {
      type: Date,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    specs: {
      type: Object,
      default: null,
    },
  },
  methods: {
    time(item) {
      if (this.created) return format(item, 'HH:mm:ss');
    },
    date(item) {
      return format(item, 'dd.MM.yyyy');
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "deletable": "При удалении сервера, вместе с ним удалится сетевой диск.",
    "deletableDisk": "Диск удалится вместе с сервером при отсутствии снапшотов."
  }
}
</i18n>
<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.layout-title {
  flexy(flex-start, center);

  &__img {
    flex: 0 0 2.5rem;
    margin-right: 1.25rem;

    img {
      display: block;
      max-width: 100%;
    }
  }

  &__content {
    flexy(space-between, center, wrap);
    width: 100%;
    &-margin {
      margin-bottom: 0.5rem
      margin-top: 0.5rem
      font-size: 14px;
      line-height: 20px;
      color: #878b99;
    }

    &::after {
      +breakpoint(sm-and-up) {
        content: '';
        display: block;
        order: 3;
        flex: 1 1 100%;
      }
    }
  }

  &__title {
    flex: 0 0 calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    order: 1;
    margin-bottom: 1rem;
    word-break: break-word;

    +breakpoint(sm-and-up) {
      flex: 1 0 100%;
      max-width: calc(100% - 3rem);
      padding-right: 0;
      margin-bottom: 0.25rem;
    }

    +breakpoint(md-and-up) {
      flex: 1 0 auto;
      max-width: 100%;
    }
  }

  &__btn {
    flex: 0 0 auto;
    order: 2;
    margin: 0 0 0 auto;
    align-self: flex-start;
  }

  &__statuses {
    display: flex;
    //flex: 1 0 auto;
    order: 3;
    margin: -0.25rem -0.25rem 1rem;

    +breakpoint(sm-and-up) {
      margin-top: 0.5rem;
    }

    +breakpoint(md-and-up) {
      flex: 0 0 auto;
      order: 5;
      margin: -0.25rem 1rem 0;
    }
    +breakpoint(lg-and-up) {
      order: 2;
    }

  }

  &__status {
    display: flex;
    flex-direction row;
    align-items: center;
    margin: 0.25rem;

  }

  &__address {
    order: 3;
    flex: 0 0 auto;
    margin: 0 auto 0 0;

    +breakpoint(sm-and-up) {
      order: 4;
      margin: 0 1.25rem 0 0;
    }
  }

  &__specs {
    flex: 0 0 100%;
    margin-top: 1.5rem;
    order: 5;

    +breakpoint(sm-and-up) {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }

}
.hover{
  text-overflow: ellipsis;
  width: 50%;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  background: var(--main-card-bg);
  z-index: 2
  &:hover {
    background: var(--main-card-bg);
    text-overflow: unset;
    overflow: visible;
    display inline
    z-index: 3;
    white-space: normal;
  }
  &:hover::after {
    +breakpoint(ml-and-up) {
      content: '';
      //display: block;
      display: inline;
      size(2.5em, 100%);
      absolute(0px, 100%);
      z-index: 2
      background: none !important;
    }
  }
}
.error {
  margin-top: 0.75rem;
}
</style>
